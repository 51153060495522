import React from 'react';

function DetailPopup(props) {
	
	let button = null;

	if (typeof(props.casestudy) != 'undefined') {
		button = <button 
			type="button" 
			className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
			onClick={props.setOpen}
			data-casestudy={props.casestudy}
			>
			Read Case Study
		</button>
	}

	if (typeof(props.getCountries) != 'undefined') {
		button = <button 
			type="button" 
			className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
			onClick={props.getCountries}
			data-casestudy={props.casestudy}
			>
			Zoom to Countries
		</button>
	}

	let taglineClasses = "text-xs uppercase";

	if (props.refData) { 
		const active_tag = props.refData.find(el => el.name === props.tagline);
		taglineClasses = taglineClasses + " text-" + active_tag.cssClass
	}

	return (<div>
				<div className="border-b border-indigo mb-2">
					<h3 className="text-lg">{props.name}</h3>
					{props.city ? (
						<>
						<span className="text-xs">{props.city}</span>
						<br />
						</>
					): null}
					{props.url ? (
						<>
						<span className="text-xs"><a href={props.url} target="_blank">{props.url}</a></span>
						<br />
						</>
					): null}
					{props.sector ? (
						<>
						<span className="text-xs capitalize">{props.sector}</span>
						<br />
						</>
					): null}
				</div>
				<div>
					{props.keyContact ? (
						<>
						<span className="text-xs font-bold">Key Contact: </span><span className="text-xs capitalize">{props.keyContact}</span>
						<br />
						</>
					): null}
					{props.activityTypes ? (
						<>
						<span className="text-xs font-bold">Activities: </span><span className="text-xs capitalize">{props.activityTypes}</span>
						<br />
						</>
					): null}
				</div>
				<div className="flex justify-start">
					{props.countries ? button : null}
					{props.casestudy ? button : null}
				</div>
				<div className="pt-2">
					<p className="text-base">
						<span className={taglineClasses}>{props.tagline}</span>
					</p>
				</div>
			</div>)
}

export default DetailPopup;