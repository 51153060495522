import React, {useEffect, useState} from 'react';

function ProgrammeSelect(props) {

    const handleSelect = (e) => {
        const newOptions = []
        Object.keys(e.target.options).map((opt) =>  {
            if (e.target.options[opt].selected) {
                newOptions.push(parseInt(e.target.options[opt].value));
            }
        });
        props.setActiveProgrammes(newOptions);
    }

    useEffect(() => {
        fetch('/api/reflocations/programmes/list/')
        .then(response => response.json())
        .then(json => {
            const newOptions = [...props.programmes, ...json];
            props.setProgrammes(newOptions);
            const newProgIds = newOptions.map((opt) => opt.id);
            props.setActiveProgrammes(newProgIds);

        });
    }, []);

    return (
        <label className="block text-left">
          <select className="form-multiselect block w-full mt-1" multiple onChange={handleSelect}>
            {props.programmes.map(opt => <option key={opt.name.toLowerCase()} className="capitalize" value={opt.id}>{opt.name.toLowerCase()}</option>)}
          </select>
        </label>
    )
}

export default ProgrammeSelect;