import React, {useRef} from 'react';
import ProgrammeSelect from './ProgrammeSelect';

function DataSwitcher(props) {

	const demogPanel = useRef();
	const activitiesPanel = useRef();
	const programmePanel = useRef();
	const alliancePanel = useRef();

	const togglePanel = (e) => {
		switch(e.target.dataset.targetpanel) {
			case 'demographics':
				demogPanel.current.classList.toggle('hidden');
				break;
			case 'activities':
				activitiesPanel.current.classList.toggle('hidden');
				break;
			case 'programmes':
				programmePanel.current.classList.toggle('hidden');
				break;
			case 'alliance':
				alliancePanel.current.classList.toggle('hidden');
		}

		
	}


	return (
		<div className="text-sm absolute bottom-4 w-11/12">
			<div className="mb-4 bg-white overflow-hidden shadow rounded-sm p-4">
			<h3 className="text-lg text-indigo border-indigo mb-2 border-b" data-targetpanel="alliance" onClick={togglePanel}>Map Data</h3>
			<div ref={alliancePanel}>
			{
				props.organisations.map((org) =>
					(
						<div key={org.name}>
							<input type="checkbox" id={org.name} name={org.name} defaultChecked={org.selected}
								onChange={(e) => {
									const options = [...props.organisations]
									options.forEach(opt => {
										if (opt.name == e.target.name) {
											opt.selected = e.target.checked;
										}
									});
									props.setOrganisations(options);
									}
								}
							></input>
							<label htmlFor={org.id} className="px-2" style={{color: org.color}}>{org.label}</label>
						</div>
					)
				)
			}
			</div>
			</div>
			{/*<div className="mb-4 bg-white overflow-hidden shadow rounded-sm p-4">
			<h3 className="font-bcW01 text-lg text-indigo border-indigo mb-2 border-b" data-targetpanel="activities" onClick={togglePanel}>British Council Activities</h3>
			<div ref={activitiesPanel}>
			{
				props.refData.map((option) => 
					(
						<div key={option.name}>
							<input type="checkbox" id={option.name} name={option.name} defaultChecked={option.selected}
								onChange={(e) => {
									const options = [...props.refData]
									options.forEach(opt => {
										if (opt.name == e.target.name) {
											opt.selected = e.target.checked;
										}
									});
									props.setRefData(options);
									}
								}
							></input>
							<label htmlFor={option.id} className="px-2" style={{color: option.color}}>{option.label}</label>
						</div>
					)
				)
			}
			</div>
			</div>
			{<div>
				<div className="mb-4 bg-white overflow-hidden shadow rounded-sm p-4">
		          <h3 className="font-bcW01 text-lg text-indigo border-indigo mb-2 border-b" data-targetpanel="programmes" onClick={togglePanel}>Programmes</h3>
		          	<div ref={programmePanel} className="hidden">
					<ProgrammeSelect setProgrammes={props.setProgrammes} programmes={props.programmes} activeProgrammes={props.activeProgrammes} setActiveProgrammes={props.setActiveProgrammes} refData={props.refData} />
					</div>
				</div>
			</div>
			<div className="mb-4 bg-white overflow-hidden shadow rounded-sm p-4">
			<h3 className="text-lg font-bcW01 text-indigo border-indigo mb-2 border-b" data-targetpanel="demographics" onClick={togglePanel}>Demographics</h3>
			<div ref={demogPanel} className="hidden">
			{
				props.demographics.map((demog) =>
					(
						<div key={demog.name}>
							<input type="radio" id={demog.name} name="demographics" className="form-radio" value={demog.name} defaultChecked={demog.selected} 
								onChange={(e) => {
										const options = [...props.demographics]
										options.forEach(opt => {
											if (opt.name == e.target.value) {
												opt.selected = e.target.checked;
											} else {
												opt.selected = false;
											}
										});
										props.setDemographics(options);
									}
								}
							/>
							<label htmlFor={demog.name} className="px-2">{demog.label}</label>
						</div>
					)
				)
			}
			</div>
			
			</div>
			*/}
		</div>
	)
}

export default DataSwitcher;