import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import graph from '../data/graph.json';
import ReactDOM from 'react-dom';
import DetailPopup from './DetailPopup';
import Legend from './Legend';
import bbox from '@turf/bbox';

mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyb25vY2FydG8iLCJhIjoiY2trbnRnajFvM2xocTJvcGF5czBsdGQ4byJ9.4AKwwY23PdK6ZD5lJUhaYQ';

function Map(props) {

	const mapContainerRef = useRef(null);
	const activities = props.activities;
	const organisations = props.organisations;
	const demographics = props.demographics;
	const refData = props.refData;
	const programmes = props.programmes;

	const [activitiesData, setActivitiesData] = useState(null);
	const [caseStudiesData, setCaseStudiesData] = useState(null);
	const [layerConfig, setLayerConfig] = useState(null);
	const [allianceMemberData, setAllianceMemberData] = useState(null);


	const popupRef = useRef(new mapboxgl.Popup({
    	closeButton: true,
    	closeOnClick: true,
    	anchor: 'left',
    	maxWidth: '300px',
	}));

	const iaPopupRef = useRef(new mapboxgl.Popup({
		closeButton: true,
    	closeOnClick: true,
    	anchor: 'left',
    	maxWidth: '400px'
	}))

	const map = props.map;

	const orgConfigs = [
		{
			id: 'british_council',
			type: 'circle',
			source: 'graph'
		},
			{
			id: 'internationalism_alliance',
			type: 'line',
			source: 'graph'
		}
	]

	const demographicsConfigs = [
		{
			id: 'school_meals',
			type: 'fill',
			source: 'bc_data',
			'source-layer': 'demographics',
			'paint': {
				'fill-color': [
				  "case",
				  ['!=', ["get", "perc_fsm"], null],
				  [
					  "step",
					  ["get", "perc_fsm"],
					  "#ffffff",
					  0,
					  "hsla(9, 100%, 64%, 0)",
					  6,
					  "hsla(46, 42%, 88%, 0.2)",
					  15,
					  "hsla(46, 42%, 88%, 0.4)",
					  26,
					  "hsla(46, 42%, 88%, 0.6)",
					  39,
					  "hsla(46, 42%, 88%, 0.8)",
					  57,
					  'hsla(46, 42%, 88%, 1)'
				  ],
				  'hsla(46, 42%, 88%, 0)'
				],
				'fill-opacity': 0.3
			}	
		},
		{
			id: 'ethnicity',
			type: 'fill',
			source: 'bc_data',
			'source-layer': 'demographics',
			'paint': {
				'fill-color': [
				  "case",
				  ['!=', ["get", "perc_wb"], null],
				  [
					  "step",
					  ["get", "perc_wb"],
					  "#ffffff",
					  0,
					  "hsla(9, 100%, 64%, 0)",
					  15,
					  "hsla(46, 42%, 88%, 0.2)",
					  42,
					  "hsla(46, 42%, 88%, 0.4)",
					  63,
					  "hsla(46, 42%, 88%, 0.6)",
					  78,
					  "hsla(46, 42%, 88%, 0.8)",
					  90,
					  'hsla(46, 42%, 88%, 1)'
				  ],
				  'hsla(46, 42%, 88%, 0)'
				],
				'fill-opacity': 0.3
			}
		},
		{
			id: 'pop_density',
			type: 'fill',
			source: 'bc_data',
			'source-layer': 'demographics',
			'paint': {
				'fill-color': [
				  "case",
				  ['!=', ["get", "pop_densit"], null],
				  [
					  "step",
					  ["get", "pop_densit"],
					  "#ffffff",
					  0,
					  "hsla(9, 100%, 64%, 0)",
					  12,
					  "hsla(46, 42%, 88%, 0.2)",
					  30,
					  "hsla(46, 42%, 88%, 0.4)",
					  51,
					  "hsla(46, 42%, 88%, 0.6)",
					  85,
					  "hsla(46, 42%, 88%, 0.8)",
					  135,
					  'hsla(46, 42%, 88%, 1)'
				  ],
				  'hsla(46, 42%, 88%, 0)'
				],
				'fill-opacity': 0.5
			}
		},
		{
			id: 'prop_no_qu',
			type: 'fill',
			source: 'bc_data',
			'source-layer': 'demographics',
			'paint': {
				'fill-color': [
				  "case",
				  ['!=', ["get", "prop_no_qu"], null],
				  [
					  "step",
					  ["get", "prop_no_qu"],
					  "#ffffff",
					  0,
					  "hsla(9, 100%, 64%, 0)",
					  14,
					  "hsla(46, 42%, 88%, 0.2)",
					  19,
					  "hsla(46, 42%, 88%, 0.4)",
					  24,
					  "hsla(46, 42%, 88%, 0.6)",
					  30,
					  "hsla(46, 42%, 88%, 0.8)",
					  40,
					  'hsla(46, 42%, 88%, 1)'
				  ],
				  'hsla(46, 42%, 88%, 0)'
				],
				'fill-opacity': 0.3
			}
		},
		{
			id: 'prop_0_25',
			type: 'fill',
			source: 'bc_data',
			'source-layer': 'demographics',
			'paint': {
				'fill-color': [
				  "case",
				  ['!=', ["get", "prop_0_25"], null],
				  [
					  "step",
					  ["get", "prop_0_25"],
					  "#ffffff",
					  0,
					  "hsla(9, 100%, 64%, 0)",
					  23,
					  "hsla(46, 42%, 88%, 0.2)",
					  27,
					  "hsla(46, 42%, 88%, 0.4)",
					  32,
					  "hsla(46, 42%, 88%, 0.6)",
					  39,
					  "hsla(46, 42%, 88%, 0.8)",
					  53,
					  'hsla(46, 42%, 88%, 1)'
				  ],
				  'hsla(46, 42%, 88%, 0)'
				],
				'fill-opacity': 0.3
			}
		},
		{
			id: 'ethnic_div',
			type: 'fill',
			source: 'bc_data',
			'source-layer': 'demographics',
			'paint': {
				'fill-color': [
				  "case",
				  ['!=', ["get", "ethnic_div"], null],
				  [
					  "step",
					  ["get", "ethnic_div"],
					  "hsla(9, 100%, 64%, 0)",
					  -0.1869,
					  "hsla(9, 100%, 64%, 0)",
					  -0.0766,
					  "#ffcccc",
					  -0.0521,
					  "#ff9999",
					  -0.0375,
					  "#ff6666",
					  -0.0256,
					  "#ff3333",
					  0,
					  '#ff0000'
					  
				  ],
				  'hsla(46, 42%, 88%, 0)'
				],
				'fill-opacity': 0.3
			}
		},
		{
			id: 'constituencies',
			'source-layer': 'constituencies',
			type: 'line',
			source: 'constituencies',
			'paint': {
				'line-color': '#aaaaaa',
				'line-width': 0.5
			}
		}
	]

	const activityConfigs = [
		{
			id: 'international_schools',
			type: 'circle',
			source: 'activities',
			'paint': {
				'circle-color': '#4B5563',
				'circle-radius': 5
			}
		}
	]


	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			center: [-3.542, 54.589],
			zoom: 5,
			attributionControl: false
		});

		map.addControl(new mapboxgl.NavigationControl());

		map.on('load', () => {
			/*
			map.addSource('bc_data', {
				'type': 'vector',
				'url': '/map/tiles/demographics.json'
			});

			map.addSource('constituencies', {
				'type': 'vector',
				'url': '/map/tiles/constituencies.json'
			});

			map.addSource('activities', {
				'type': 'vector',
				'url': '/map/tiles/activities.json'
			});

			
			map.addSource('organisations', {
				'type': 'vector',
				'url': '/map/tiles/organisations.json'
			});*/

			map.addSource('alliance', {
				'type': 'geojson',
				'data': '/api/alliancemembers/list/'
			});

			/*
			map.addLayer({
				'id': 'organisations',
				'type': 'circle',
				'source': 'organisations',
				'source-layer': 'organisations',
				'paint': {
					'circle-color': [
						'case',
							['==', ['get', 'l2_indicators'], 'Schools'],
							'#ff8200',
							['==', ['get', 'l2_indicators'], 'Government'],
							'#ea0034',
							['==', ['get', 'l2_indicators'], 'Organisations - Arts'],
							'#ff00c8',
							['==', ['get', 'l2_indicators'], 'Organisations - Exam Boards'],
							'#b25eff',
							['==', ['get', 'l2_indicators'], 'Organisations - Further Education'],
							'#00edc3',
							['==', ['get', 'l2_indicators'], 'Organisations - Other'],
							'#035c67',
							['==', ['get', 'l2_indicators'], 'Organisations - Tertiary'],
							'#bd16b0',
							['==', ['get', 'l2_indicators'], 'Organisations – CSO/NGO'],
							'#ff4635',
						'#268bd2',
					],
					'circle-radius': ['interpolate', ['linear'], ['zoom'], 6, 1.5, 12, 6]
				},
				'filter': ['!=', ['get', 'l2_indicators'], 'Schools']
			});
			*/

			map.addLayer({
				'id': 'case_studies',
				'type': 'circle',
				'source': 'alliance',
				// 'source-layer': 'activities',
				'filter': ['!=', ['to-number', ['get', 'casestudy']], 0],
				'paint': {
					'circle-color': '#FCD34D',
					'circle-radius': ['interpolate', ['linear'], ['zoom'], 6, 3, 12, 12]
				}
			})

			map.addLayer({
				'id': 'alliance',
				'type': 'circle',
				'source': 'alliance',
				'filter': ['==', ['to-number', ['get', 'casestudy']], 0],
				'paint': {
					'circle-color': '#ea41e7',
					'circle-radius': ['interpolate', ['linear'], ['zoom'], 6, 3, 12, 12]
				}
			});

			/*

			map.on('mouseenter', 'activities', e => {
				map.getCanvas().style.cursor = 'pointer';
			});

			map.on('mouseleave', 'activities', e => {
				map.getCanvas().style.cursor = '';
			});
			*/

			map.on('mouseenter', 'case_studies', e => {
				map.getCanvas().style.cursor = 'pointer';
			});

			map.on('mouseleave', 'case_studies', e => {
				map.getCanvas().style.cursor = '';
			});

			map.on('mouseenter', 'alliance', e => {
				map.getCanvas().style.cursor = 'pointer';
			});

			map.on('mouseleave', 'alliance', e => {
				map.getCanvas().style.cursor = '';
			});


			map.on('click', 'alliance', e => {
				console.log(e.features[0].properties);
				const feature = e.features[0];
				const lngLat = e.lngLat;
				const name = feature.properties.name;
				
				// Alliance props
				const url = feature.properties.url;
				const socials = feature.properties.socials;
				const sector = feature.properties.sector;
				const keyContact = feature.properties.key_contact;
				const activityTypes = feature.properties.activity_types;
				const countries = feature.properties.countries;

				const tagline = 'Internationalism Alliance Organisation';

				const detailPopupHtmlNode = document.createElement('div');
				
				ReactDOM.render(<DetailPopup name={name} setOpen={props.setOpen} tagline={tagline} url={url} socials={socials} sector={sector} keyContact={keyContact} activityTypes={activityTypes} countries={countries} alliance={true} />, detailPopupHtmlNode);

				iaPopupRef.current.setLngLat(lngLat).setDOMContent(detailPopupHtmlNode).addTo(map);

			});


			map.on('click', 'case_studies', e => {
				const feature = e.features[0];
				const lngLat = e.lngLat;
				const name = feature.properties.name;
				const casestudy = feature.properties.casestudy;
				console.log(feature.properties);


				const detailPopupHtmlNode = document.createElement('div');

				let tagline = null;

				if (feature.properties.activity_type == 'IA') {
			    	tagline = 'Internationalism Alliance Organisation';
			    } else if (feature.properties.activity_type == 'BC') {
			    	tagline = 'International School Award';
			    }

				ReactDOM.render(<DetailPopup name={name} setOpen={props.setOpen} casestudy={casestudy} tagline={tagline} name={name} url={feature.properties.url} sector={feature.properties.sector} keyContact={feature.properties.key_contact} activityTypes={feature.properties.activity_types} />, detailPopupHtmlNode);

				popupRef.current.setLngLat(lngLat).setDOMContent(detailPopupHtmlNode).addTo(map);

			});

		});
	
		return () => map.remove();
	}, []);

	return (
		<div className="h-full">
	      <div ref={mapContainerRef} className='map-container h-full'>
	      	<div className='map-overlay h-full w-full flex items-end justify-end pointer-events-none'>
	      		<div className=''>
	      			<Legend layerConfig={layerConfig} demographics={demographics}/>
	      		</div>
	      	</div>
	      </div>
	    </div>
	)

}

export default Map;