import React, { useRef, useEffect, useState } from 'react';
import '/src/styles.css';
import Map from './components/BCMap';
import DataSwitcher from './components/DataSwitcher';
import CaseStudyOverlay from './components/CaseStudyOverlay';
import IndexPanel from './components/IndexPanel';
import 'mapbox-gl/dist/mapbox-gl.css';

function App() {

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const organisationOptions = [
  	{
  		'name': 'IA',
  		'label': 'Internationalism Alliance Members',
  		'selected': true,
      'color': '#936cc4'
  	},
  	{
  		'name': 'CS',
  		'label': 'Case Studies',
  		'selected': true,
      'color': '#FCD34D'
  	}
  ];

  const activityOptions = [
  	{
  		'name': 'organisation',
  		'label': 'Organisation',
  		'choices': [
  			{
  				'name': 'all',
  				'label': 'All',
  				'selected': true
  			},
  			{
  				'name': 'SCHOOL',
  				'label': 'Schools',
  				'selected': false
  			},
  			{
  				'name': 'HE',
  				'label': 'Higher Education',
  				'selected': false
  			},
  			{
  				'name': 'FE',
  				'label': 'Further Education',
  				'selected': false
  			},
  			{
  				'name': 'COMMUNITY',
  				'label': 'Community',
  				'selected': false
  			},
  			{
  				'name': 'ARTS',
  				'label': 'Arts',
  				'selected': false
  			}
  		]
  	},
  	{
  		'name': 'programmes',
  		'label': 'Programmes',
  		'choices': [
  			{
  				'name': 'all',
  				'label': 'All',
  				'selected': true
  			},
  			{
  				'name': 'international_schools',
  				'label': 'International School Awards',
  				'selected': false
  			},
  			{
  				'name': 'turing',
  				'label': 'Turing',
  				'selected': false
  			},
  			{
  				'name': 'erasmus',
  				'label': 'Erasmus +',
  				'selected': false
  			},
  			{
  				'name': 'active_citizens',
  				'label': 'Active Citizens',
  				'selected': false
  			}
  		]
  	}
  ];

  const layerOptions = [
  	{
  		'name': 'case_studies',
  		'label': 'Case Studies',
  		'color': '#c1c1c1',
  		'selected': true
  	},
  	{
  		'name': 'activities',
  		'label': 'Activities',
  		'color': '#a1a1a1',
  		'selected': true
  	}
  ];

  const demographicOptions = [
  	{
  		'name': 'none',
  		'label': 'None',
  		'color': '#f7f7f7',
  		'selected': true
  	},
  	{
  		'name': 'school_meals',
  		'label': 'Eligable for Free School Meals',
  		'color': '#f7f7f7',
  		'selected': false,
      'metric': '%'
  	},
  	{
  		'name': 'ethnicity',
  		'label': 'White British Population',
  		'color': '#f7f7f7',
  		'selected': false,
      'metric': '%'
  	},
  	{
  		'name': 'pop_density',
  		'label': 'Population Density',
  		'color': '#f7f7f7',
  		'selected': false,
      'metric': 'People per Hectare'
  	},
    /*{
      'name': 'diversity',
      'label': 'Ethnic Diversity',
      'color': '#f7f7f7',
      'selected': false
    },*/
    {
      'name': 'prop_no_qu',
      'label': 'People with No Qualifications',
      'color': '#f7f7f7',
      'selected': false,
      'metric': '%'
    },
    {
      'name': 'prop_0_25',
      'label': 'Under 25s',
      'color': '#f7f7f7',
      'selected': false,
      'metric': '%'
    },
  	{
  		'name': 'constituencies',
  		'label': 'UK Parliamentary Constituencies',
  		'color': '#f7f7f7',
  		'selected': false
  	}
  ];

  const refDataOptions = [
    {
      'name': 'Schools',
      'label': 'Schools',
      'selected': false,
      'color': '#ff8200',
      'cssClass': 'lightorange'
    },
    {
      'name': 'Government',
      'label': 'Government',
      'selected': true,
      'color': '#ea0034',
      'cssClass': 'red'
    },
    {
      'name': 'Organisations - Arts',
      'label': 'Arts',
      'selected': true,
      'color': '#ff00c8',
      'cssClass': 'pink'
    },
    {
      'name': 'Organisations - Exam Boards',
      'label': 'Exam Boards',
      'selected': true,
      'color': '#b25eff',
      'cssClass': 'purple'
    },
    {
      'name': 'Organisations - Further Education',
      'label': 'Further Education',
      'selected': true,
      'color': '#00edc3',
      'cssClass': 'teal'
    },
    {
      'name': 'Organisations - Tertiary',
      'label': 'Tertiary',
      'selected': true,
      'color': '#bd16b0',
      'cssClass': 'darkpurple'
    },
    {
      'name': 'Organisations – CSO/NGO',
      'label': 'CSOs and NG0s',
      'selected': true,
      'color': '#ff4635',
      'cssClass': 'darkorange'
    }
  ];

  // State Variables

  // Map and map layers
  const [layers, setLayers] = useState(layerOptions);
  const [activities, setActivities] = useState(activityOptions);
  const [demographics, setDemographics] = useState(demographicOptions);
  const [organisations, setOrganisations] = useState(organisationOptions);
  const [refData, setRefData] = useState(refDataOptions);
  const [map, setMap] = useState(null);
  const [programmes, setProgrammes] = useState([]);
  const [activeProgrammes, setActiveProgrammes] = useState([]);
  const [infoText, setInfoText] = useState(null);

  // Menu navigation
  const [navigation, setNavigation] = useState([]);

  // Case study overlay
  const [overLayOpen, setOverLayOpen] = useState(false);
  const [overLayContent, setOverLayContent] = useState({title: 'none', 'body': 'none'});

  // Case study / Alliance partners indexes
  const [indexPageOpen, setIndexPageOpen] = useState(false);
  const [indexPageUrl, setIndexPageUrl] = useState(null);
  const [indexPageTitle, setIndexPageTitle] = useState(null);


  const onActivityChange = options => {
  	setActivities(options);
  }

  const onOrganisationsChange = options => {
  	setOrganisations(options);
  }

  const onDemographicsChange = options => {
  	setDemographics(options);
  }

  const onLayerChange = options => {
  	setLayers(options);
  }

  const onClickShowCaseStudy = e => {
  	const casestudy = e.target.dataset.casestudy;
  	fetch('/api/casestudies/' + casestudy)
  		.then(response => response.json())
  		.then(data => {
  			setOverLayContent(data);
  			setOverLayOpen(true);
  		});
  }

  const onClickShowPage = e => {
    const url = e.target.dataset.url;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setOverLayContent(data);
        setOverLayOpen(true);
      });
  }

  const onClickShowIndexPage = e => {
    if (indexPageOpen === true && indexPageUrl === e.target.dataset.url) {
      setIndexPageOpen(false);
    } else {
      setIndexPageUrl(e.target.dataset.url);
      setIndexPageTitle(e.target.dataset.indextitle);
      setIndexPageOpen(true);  
    }
  }

  const hideIndexPage = e => {
    setIndexPageOpen(false);
  }

  useEffect(() => {
    fetch('/api/pages/')
      .then(response => response.json())
      .then(data => {
        data.map(link => {
          setNavigation(navigation => [...navigation, {name: link.title, href: '/api/pages/' + link.slug + '/', current: false}]);
        });
      })
  }, []);

  useEffect(()=> {
    fetch('/api/staticcontent/info/')
    .then(response => response.json())
    .then(data =>
      {
        setInfoText(data);
      })
  }, [])


  return (
    <div>
      <div className="hidden w-full h-16 md:flex md:fixed md:inset-y-0 z-40 bg-white shadow-sm">
        <div className="flex items-center flex-shrink-0 px-4 w-full">
            <div className="w-1/2 flex items-center">
              <h1 className="text-xl font-bold text-indigo">UK Global Alliance Map</h1>
            </div>
            <div className="hidden w-1/2 sm:-my-px sm:ml-6 sm:flex sm:space-x-8 flex justify-end">
                <a 
                  href='#'
                  className={classNames(
                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                  )}
                  data-indextitle = 'Case Studies Index'
                  onClick={hideIndexPage}
                >
                  Map
                </a>
                <a 
                  href='#'
                  className={classNames(
                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                  )}
                  data-url='/api/alliancemembers/list/'
                  data-indextitle = 'Internationalism Alliance Members'
                  onClick={onClickShowIndexPage}
                >
                  Internationalism Alliance
                </a>
                {navigation ? navigation.map((item) => (
                  <a
                    key={item.name}
                    href='#'
                    data-url={item.href}
                    className={classNames(
                      item.current
                        ? 'border-indigo-500 text-gray-900'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                    onClick={onClickShowPage}
                  >
                    {item.name}
                  </a>
                )) : null
              }
            </div>
          </div>
      </div>
      <IndexPanel show={indexPageOpen} url={indexPageUrl} title={indexPageTitle} setOpen={onClickShowCaseStudy} />
      <div className="hidden md:flex md:w-80 md:flex-col md:fixed md:inset-y-0">
      	<div className="flex flex-col flex-grow pt-5 overflow-y-auto shadow z-50 text-indigo bg-white">
	        <div className="mt-5 flex-1 flex flex-col px-4 font-serif relative">
            {infoText ? 
                <div className="mt-10 mb-4 bg-white overflow-hidden shadow rounded-sm p-4">
                  <h2 className="mb-4 text-lg font-bold border-b">{infoText.title}</h2>
                  <p className="">{infoText.content}</p>
                </div>
            : null
            }
	        </div>
        </div>
      </div>
      <div className="md:pl-80 flex flex-col flex-1 h-screen">
	        <div className="h-full pt-16">
	          <div className="h-full">
	            <Map layers={layers} setLayers={setLayers} activities={activities} setActivities={setActivities} demographics={demographics}setDemographics={setDemographics} organisations={organisations} map={map} setMap={setMap} setOpen={onClickShowCaseStudy} refData={refData} programmes={programmes} activeProgrammes={activeProgrammes}/>
	          </div>
	        </div>
	        <CaseStudyOverlay open={overLayOpen} setOpen={setOverLayOpen} content={overLayContent} />
      </div>
      
    </div>
  );
}

export default App;