import React, { useRef, useEffect, useState } from 'react';

function IndexPanel(props) {

	const [panelCaseStudies, setPanelCaseStudies] = useState([]);
	const [panelIaMembers, setPanelIaMembers] = useState([])

	useEffect(() => {
		if (props.url) {
			fetch(props.url)
			.then(response => response.json())
			.then(data => {
				// This is hardcoded, hacky and bad.
				switch(props.title) {
					case 'Case Studies Index':
						setPanelIaMembers([])
						data.map(cs => {
							setPanelCaseStudies(panelCaseStudies => [...panelCaseStudies, {title: cs.title, id: cs.id, body: cs.body.slice(0, 300)}]);  
						});
						break;
					case 'Internationalism Alliance Members':
						setPanelCaseStudies([])
						data.features.map(iam => {
							setPanelIaMembers(panelIaMembers => [...panelIaMembers, {name: iam.properties.name, url: iam.properties.url, socials: iam.properties.socials, sector: iam.sector, keyContact: iam.properties.key_contact, beneficiaries: iam.properties.beneficiaries, activityTypes: iam.properties.activity_types, countries: iam.properties.countries, caseStudy: iam.properties.casestudy }])
						});
						break;
				}
			});
		}
	}, [props.url])

	return (
		props.show ?
		<div className="hidden md:flex md:w-full md:flex-row md:fixed md:inset-y-0 mt-16 z-20 bg-white pt-4 overflow-scroll">
	        <div className="mx-8 md:w-1/2">
		        <h1 className="text-xl border-indigo mb-6 border-b">{props.title}</h1>
		        {panelCaseStudies ? panelCaseStudies.map((item) => (
		        	<div key={item.title} className="mb-4 bg-white overflow-hidden shadow rounded-lg p-4">
		        		<h2 className="">{item.title}</h2>
		        		<div dangerouslySetInnerHTML={{__html: item.body}}>
		        		</div>
		        		<button 
							type="button" 
							className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-2 bg-lightgrey"
							onClick={props.setOpen}
							data-casestudy={item.id}
							>
							Read Case Study
						</button>
		        	</div>
		        )) : null }
		        {panelIaMembers ? panelIaMembers.map((item) => (
		        	<div key={item.name} className="mb-4 bg-white overflow-hidden shadow rounded-lg p-4">
		        		<div className="border-b border-indigo mb-2 pb-2">
			        		<h2 className="text-lg">{item.name}</h2>
		        		</div>
		        		<p><a href={item.url} target="_blank">{item.url}</a></p>
						<p><span className="capitalize">{item.sector}</span></p>
						<p><span className="font-bold">Key Contact: </span><span className=" capitalize">{item.keyContact}</span></p>
						<p><span className="font-bold">Activities: </span><span className="capitalize">{item.activityTypes}</span></p>
						<p><span className="font-bold">Countries: </span><span className="capitalize">{item.countries}</span></p>
						{
							item.caseStudy ? (
								<button 
									type="button" 
									className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-2 bg-lightgrey"
									onClick={props.setOpen}
									data-casestudy={item.caseStudy}
									>
									Read Case Study
								</button>
							) :
							null
						}
		        	</div>
		        )): null}
	        </div>
	    </div> 
	    : null
	)
}

export default IndexPanel;