import React from 'react';

function Legend(props) {

	let title = null;
	let metric = null;
	let blocks = null;

	if (props.layerConfig && props.layerConfig.paint["fill-color"]) {

		title = props.demographics.filter(option => {
			if (option.name == props.layerConfig.id) {
				return option.name
			}
		});

		const fill = props.layerConfig.paint["fill-color"][2];
		const steps = fill.filter(step => {
			if (typeof(step) == 'number') {
				return step;
			} else if (step[0] == '#' || step[0] == 'h') {
				return step
			}
		});

		const stepSetting = {
			0: 'hsla(9, 100%, 64%, 0)'
		};

		// Populate the steps with colours

		for (let i=0; i<steps.length; i++) {
			if (typeof(steps[i]) == 'number') {
				stepSetting[steps[i]] = steps[i+1];
			}
		}

		blocks = Object.keys(stepSetting).map(key => {
			const style = {'backgroundColor': stepSetting[key]};
			return (
				<div key={key}>
					<div className="w-6 h-6 block rounded-sm" style={style}></div>
					<span>{key}</span>
				</div>
			)
		});
		
	} else {
		return <div></div>;
	}

	return (
		<div className="p-4 m-4 rounded-tr-xl rounded-bl-xl w-64 text-yellow-50 bg-white">
			<h3 className="text-center text-sm font-serif font-bold mb-2">{title ? title[0].label : null} ({title ? title[0].metric : null })</h3>
			<div className="flex w-auto space-x-2 justify-center bg-grey pt-2 rounded-sm">
				{ blocks }
			</div>
		</div>
	)
}

export default Legend;